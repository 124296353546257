<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1000px" persistent>
      <v-card>
        <v-toolbar color="teal lighten-2" dark>
          <v-toolbar-title class="ml-2">Creación de Usuarios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
            >X</v-btn
          >
        </v-toolbar>
        <v-container>
          <v-card>
            <v-form ref="refDialogIngresaUsuario" class="pa-2">
              <v-row>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmNombre"
                    label="Nombre..."
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmEmail"
                    label="Email..."
                    :rules="[rules.required, rules.emailRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    v-model="vmPassword"
                    label="Contraseña..."
                    dense
                    outlined
                    :rules="[rules.required, rules.minCharactersPassword]"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <template>
                    <v-radio-group v-model="vmType" mandatory>
                      <v-radio label="Administrador" value="ADM"></v-radio>
                      <v-radio label="Usuario Normal" value="USR"></v-radio>
                    </v-radio-group>
                  </template>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <div class="text-center">
                <v-btn class="mt-2" @click="submitCreate" dark color="success"
                  >Crear</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from "../../../service/api/usuarios/index";
export default {
  name: "dialog-create-usuario",
  // props: {},
  data: function() {
    return {
      rules: {
        required: (value) => !!value || "Requerido.",
        emailRule: (value) => /.+@.+\..+/.test(value) || "Email inválido.",
        minCharactersPassword: (value) =>
          (value && value.length >= 6) || "Mínimo de 6 caracteres.",
      },
      openDialog: false,
      vmEmail: "",
      vmNombre: "",
      vmType: null,
      show1: false,
      vmPassword: '',
    };
  },
  computed: {
    validateForm() {
      return this.$refs.refDialogIngresaUsuario.validate();
    },
    resetForm() {
      return this.$refs.refDialogIngresaUsuario.reset();
    },
  },
  methods: {
    closeDialog() {
      this.resetForm;
      this.openDialog = false;
    },
    async submitCreate() {
      if (this.validateForm) {
        let data = {};
        data.name = this.vmNombre;
        data.email = this.vmEmail;
        data.type = this.vmType;
        data.password = this.vmPassword;
        let response = await UserService.create(data);
        if (response.status === 201) {
          this.closeDialog();
          this.$emit("successResponse");
        } else {
          this.closeDialog();
          this.$emit("errorResponse", response);
        }
      }
    },
  },
};
</script>
