<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1000px" persistent>
      <v-card>
        <v-toolbar color="teal lighten-2" dark>
          <v-toolbar-title class="ml-2"
            >Edición de Usuarios</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
            >X</v-btn
          >
        </v-toolbar>
        <v-container>
          <v-card>
            <v-form ref="refDialogEditarUsuario" class="pa-2">
              <v-row>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmNombre"
                    label="Nombre..."
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmEmail"
                    label="Email..."
                    :rules="[rules.required, rules.emailRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <template>
                    <v-radio-group v-model="vmType" mandatory>
                      <v-radio label="Administrador" value="ADM"></v-radio>
                      <v-radio label="Usuario Normal" value="USR"></v-radio>
                    </v-radio-group>
                  </template>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <div class="text-center">
                <v-btn
                  class="mt-2"
                  @click="saveEditUser"
                  dark
                  color="success"
                  >Guardar</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from "../../../service/api/usuarios/index";
export default {
  props: {
    DatosUser: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    validateForm() {
      return this.$refs.refDialogEditarUsuario.validate();
    },
    resetForm() {
      return this.$refs.refDialogEditarUsuario.reset();
    },
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || "Requerido.",
        emailRule: (value) => /.+@.+\..+/.test(value) || "Email inválido.",
        minCharactersPassword: (value) =>
          (value && value.length >= 6) || "Mínimo de 6 caracteres.",
      },
      items: [],
      openDialog: false,
      vmEmail: "",
      vmNombre: "",
      vmType: null,
      vmPassword: ""
    };
  },
  methods: {
    getValues() {
      this.vmNombre = this.DatosUser.name;
      this.vmEmail = this.DatosUser.email;
      if (this.DatosUser.type == "ADM") {
        this.vmType = "ADM";
      } else {
        this.vmType = "USR";
      }
    },
    async saveEditUser() {
      if (this.validateForm) {
        let user = {};
        user.email = this.vmEmail;
        user.name = this.vmNombre;
        user.type = this.vmType;
        let response = await UserService.update(
          user,
          this.DatosUser.id
        );
        if (response.status === 200) {
          this.closeDialog();
          this.$emit("successResponse");
        } else {
          this.closeDialog();
          this.$emit("errorResponse", response);
        }
      }
    },

    closeDialog() {
      this.openDialog = false;
    },
  },
  watch: {
    DatosUser(val) {
      if (val) {
        this.getValues();
      }
    },
  },
};
</script>
