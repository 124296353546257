import axios from "axios";

const fetchAll = async function () {
    try {
        const response = await axios.get(`/api/users`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchAll;