<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span>Usuarios</span>
        <v-spacer />
        <v-btn color="success" dark @click="openAdd">
          <v-icon class="mr-2"> mdi-plus </v-icon>Agregar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            v-if="load"
            indeterminate
            color="blue"
          ></v-progress-circular>
        </div>
        <v-data-table
          v-if="!load"
          dense
          :headers="headers"
          :items="items"
          no-data-text="Sin Contenido"
          no-results-text="Sin resultados encontrados"
          item-key="id"
          class="elevation-10"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="warning" class="mr-2" @click="openEdit(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="red" @click="openDelete(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <v-chip class="ma-2" dark outlined color="primary">{{
              item.prov_acre
            }}</v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <IngresaUsuario
      ref="refDialogIngresaUsuario"
      @successResponse="successResponse"
      @errorResponse="errorResponse"
    />
    <EditarUsuario
      :DatosUser="userObj"
      ref="refDialogEditarUsuario"
      @successResponse="successResponse"
      @errorResponse="errorResponse"
    />

    <SnackbarConfirm
      :messageSnackbar="messageSnackbar"
      @successResponse="openConfirm"
      ref="refSnackbarDelete"
    ></SnackbarConfirm>
    <SnackbarError
      :messageSnackbar="messageSnackbar"
      @openConfirm="errorResponse"
      ref="refSnackbarError"
    />
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
        <h3>{{ texto_snackbar }}</h3>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import SnackbarError from "../../components/Snackbar/SnackbarError";
import SnackbarConfirm from "../../components/Snackbar/SnackbarConfirm";
import IngresaUsuario from "./dialog/IngresaUsuario";
import UserService from "../../service/api/usuarios/index";
import EditarUsuario from "./dialog/EditarUsuario";

export default {
  name: "Usuarios",
  components: {
    IngresaUsuario,
    EditarUsuario,
    SnackbarConfirm,
    SnackbarError,
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Email", value: "email" },
        { text: "Tipo Usuario", value: "type" },
        { text: "Acción", value: "actions", sortable: false },
      ],
      userObj: {},
      messageSnackbar: "",
      snackbar: false,
      timeout: 2000,
      texto_snackbar: "",
      load: false,
    };
  },
  methods: {
    successResponse() {
      this.getValues();
    },
    errorResponse(response) {
      this.$refs.refSnackbarError.openSnackbar = true;
      this.messageSnackbar = `Error!! El Correo ingresado, ya está registrado`;
      console.log(response)
    },
    async getValues() {
      this.load = true;
      let response = await UserService.fetchAll();
      (this.items = response.map((resp) => {
        if (resp.type == "ADM") {
          resp.prov_acre = "Administrador";
        }
        if (resp.type == "USR") {
          resp.prov_acre = "Usuario Normal";
        }
        return resp;
      })),
      this.load = false;
    },
    openAdd() {
      this.$refs.refDialogIngresaUsuario.openDialog = true;
    },
    openEdit(item) {
      this.userObj = item;
      this.$refs.refDialogEditarUsuario.openDialog = true;
    },
    openDelete(item) {
      this.$refs.refSnackbarDelete.openSnackbar = true;
      this.messageSnackbar = `¿Desea eliminar a ${item.name}?`;
      this.userObj = item;
    },
    async openConfirm() {
      let response = await UserService.destroy(this.userObj.id);
      if (response.data === 200) {
        this.$refs.refSnackbarDelete.openSnackbar = false;
        this.successResponse();
      } else this.errorResponse(response);
    },
  },
  mounted() {
    this.getValues();
  },
};
</script>
