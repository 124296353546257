import fetchAll from "./fetchAll";
import create from "./create";
import update from "./update";
import destroy from "./destroy";

export default {
    fetchAll,
    create,
    update,
    destroy
}